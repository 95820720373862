import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import { Layout } from 'containers';
import { Banner } from 'components';
import { BASE_URL } from 'config';

const JobsPage = () => (
  <Layout page="jobs">

    <Helmet
      title="Haka Tours | Snow Tour Manager"
      meta={[
        {
          name: 'description', content: 'SNOW TOUR MANAGER',
        },
        { name: 'og:title', content: 'Haka Tours | Snow Tour Manager' },
        {
          name: 'og:description', content: 'SNOW TOUR MANAGER',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/jobs/` }]}
    />

    <Banner modifier="c-banner--tiny" backgroundImage="https://static-hakatours.imgix.net/footer-bg.jpg?auto=format&fit=crop">
      <h1 className="c-heading c-heading--h1">SNOW TOUR MANAGER</h1>
    </Banner>
    <section className="l-section">
      <div className="l-container">
        <h2 className="c-heading c-heading--h2"><Link to="/new-zealand-snow-tours">Snow Tour Manager (Club and Commercial Ski Fields)</Link></h2>
        <p>
          Now is a truly exciting time to join our whānau at Haka Tours! We are in a phase of huge regrowth alongside our majority shareholders
          Intrepid Travel Group and are looking for an experienced and skilled snow expert to join our team. If you have an insatiable enthusiasm for NZ snow,
          love to interact with guests, and put the customer service first, you’ll certainly feel at home working as a Tour Manager with Haka Tours.
          If this sounds like you and you want to apply, please email your CV, along with a Cover Letter to detail why you would make a perfect Snow
          Guide to <a href="mailto: carl@hakatours.com">carl@hakatours.com</a>.
        </p>
        <p>This is a niche and environmentally dependent role, so experience is necessary.</p>
        <p>
          We’re seeking a proficient Driver-Guide with a background in snow sport and has extensive experience across both South Island Club and
          Commercial Ski Fields, to run our Haka Snow Tours this winter. This role requires a person who is able to adapt quickly and efficiently
          within ever changing mountain environments, whilst operating to a high health and safety standard.
        </p>
        <br />
        <h3>Role Requirements:</h3>
        <ul>
          <li>Experience driving South Island mountain access roads</li>
          <li>Tow rope/nut-cracker experience</li>
          <li>Competence in weather and snow reports</li>
          <li>Chain Fitting</li>
          <li>Class 2 Licence</li>
          <li>P Endorsement</li>
          <li>Manual Driving</li>
          <li>Guiding/Leadership Experience</li>
          <li>Knowledge of and adherence to driving regulations and laws</li>
          <li>First Aid Qualification</li>
        </ul>
        <br />
        <h3>Customer Experience & Product Delivery:</h3>
        <ul>
          <li>Escort groups across South Island Club Fields (Porters, Broken River, Craigieburn, Temple Basin, Mt Olympus, Roundhill, Dobson, and Ōhau)</li>
          <li>Escort groups across South Island Ski Resorts (Mt Hutt, Treble Cone, Cardrona, Coronet Peak, and Remarkables)</li>
          <li>Ensure every customer has a “world class touring experience” with Haka Tours</li>
          <li>If, for unforeseen circumstances, there are changes to the advertised itinerary, responsibility for promoting and protecting the guest safety.</li>
        </ul>
        <br />
        <h3>Desirable Attributes:</h3>
        <ul>
          <li>Instructor Experience</li>
          <li>Patrol Experience</li>
          <li>Avalanche Training</li>
          <li>Backcountry Experience</li>
          <li>General Snow Industry Experience</li>
        </ul>
        <br />
        <h3>Personal Attributes:</h3>
        <ul>
          <li>Ability to juggle multiple tasks and work in a fast-paced travel environment.</li>
          <li>Calm under pressure, and reassuring when issues arise.</li>
          <li>Attention to detail, highly organised, and flexible.</li>
          <li>Excellent interpersonal, and communication skills.</li>
          <li>Ability to establish and nurture relationships with Haka Tours’ partners.</li>
          <li>A sociable, positive and open attitude.</li>
          <li>An easy-going personality where the customer always comes first.</li>
          <li>Excellent knowledge of Māori Culture.</li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default JobsPage;
